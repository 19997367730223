<template>
  <div class="assets">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul class="list">
          <li class="item" v-for="item in list" :key="item.id">
            <div class="header">
              <div class="left">
                <span class="title">{{ item.assetName }}</span>
                <span class="sub">({{ item.serNum }})</span>
              </div>
              <van-tag color="#E6FAF2" text-color="#009B5D">{{
                item.statusName
              }}</van-tag>
            </div>
            <div class="body">
              <ul class="ul">
                <li>资产分类 {{ item.assetName }}</li>
                <li v-if="item.status === 2">借用人 {{item.borrowerName}} ({{item.borrowerTel}})</li>
                <li>资产管理员 {{ item.supplierName }}</li>
              </ul>
              <van-image
                @click="handleQrcodeClick(item)"
                class="qrcode"
                :src="item.codeImg"
              />
            </div>
            <div class="footer">
              <van-button v-if="[1,2].includes(item.status)" round type="info" @click="handleStatusClick(item)">{{
                item.status | formatStatus
              }}</van-button>
            </div>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh, Button, Tag, Image, Dialog, Toast } from "vant";
import { officeassetRepay, getOfficeassetPage } from "@/api/asset";
export default {
  name: "assets",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Image.name]: Image,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: {
        current: 0,
        size: 10,
      },
    };
  },
  mounted() {
    this.onLoad();
  },
  filters: {
    formatStatus(val) {
      switch (val) {
        case 1:
          return "借用";
        case 2:
          return "归还";
        default:
          return "";
      }
    },
  },
  methods: {
    handleStatusClick(item) {
      if (item.status === 1) {
        this.$router.push({
          path: "/apply",
          query: {
            id: item.id,
          },
        });
      } else if (item.status === 2) {
        this.giveBack(item);
      }
    },
    giveBack(item) {
      Dialog.confirm({
        title: "提示",
        message: "确定归还？",
      })
        .then(async () => {
          const res = await officeassetRepay({
            assetId: item.id,
            userName:item.borrowerName,
            phone: item.borrowerTel
          });
          console.log(res);
          Toast(res.msg)
          item.status = 1
          item.statusName = '仓库中'
        })
        .catch(() => {
          // on cancel
        });
    },
    handleQrcodeClick(item) {
      this.$router.push({
        path: "/qrcode",
        query: {
          codeImg: item.codeImg,
        },
      });
    },
    onRefresh() {
      this.finished = false;
      this.page.current = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page.current++;
      this.getList();
    },
    async getList() {
      const res = await getOfficeassetPage({
        current: this.page.current,
        size: this.page.size,
      });
      // console.log(res)
      this.refreshing = false;
      this.loading = false;
      if (!res.data.records.length) {
        this.finished = true;
      }
      this.list = this.list.concat(res.data.records);
    },
  },
};
</script>

<style scoped lang="scss">
.assets {
  padding: 10px 16px;
  .list {
    .item {
      background: #ffffff;
      margin-bottom: 8px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        height: 30px;
        border: 1px solid #e6e6e6;
        .left {
          display: flex;
          align-items: center;
          .title {
            font-size: 14px;
            color: #0c0f18;
          }
          .sub {
            font-size: 12px;
            color: #85878b;
          }
        }
      }
      .body {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          li {
            font-size: 14px;
            color: #666666;
            line-height: 1.6;
          }
        }
        .qrcode {
          width: 60px;
          height: 60px;
        }
      }
      .footer {
        display: flex;
        height: 40px;
        padding: 0 12px;
        align-items: center;
        justify-content: flex-end;
        .van-button {
          width: 76px;
          height: 24px;
        }
      }
    }
  }
}
</style>